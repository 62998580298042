import React from 'react'
import FadeIn from 'react-fade-in';
import { Container, Row, Col } from 'react-bootstrap'; 
import './ConsentConfirmedInvalidLink.css';

export default function ConsentConfirmedInvalidLink(props) {

    const {lenderId, language} = props;
    
    const addDefaultLogoSrc = (event) => {
        event.target.src = process.env.PUBLIC_URL + './dealertrack-logo-vector.svg';
        event.target.style.margin = "0rem";
    };

    return (
        <div>
            <FadeIn transitionDuration="2000">
                <div className="consent-confirmation-invalid__wrapper">       
                    <Container>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3}>
                                <div>
                                    <img id="consent-confirmation-invalid-logo" onError={addDefaultLogoSrc} className="consent-confirmation-invalid-logo" src={`${process.env.REACT_APP_IMAGES}${lenderId.toUpperCase()}/${lenderId.toUpperCase()}logo_update${language.toUpperCase().replace("-CA", "")}.png`} alt="logo"/>
                                </div>
                                <div className="consent-confirmation-invalid__confirmed-image-text">
                                    {props.underLogoText}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={5}>
                                <img className="consent-confirmation-invalid__confirmed-image" alt="confirmed" src={process.env.PUBLIC_URL + props.documentImageInvalid} />       
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <div className="consent-confirmation-invalid__align-text-left">
                                   <div>{props.headerText}</div>
                                   <div>{props.bodyText}</div>
                                </div> 
                            </Col>
                        </Row>           
                    </Container>
                </div>
            </FadeIn>
        </div>
    )
}
