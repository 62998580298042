import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en/translations.json';
import translationFR from './locales/fr/translations.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    "en-CA": {
      translation: translationEN
    },
    "fr-CA": {
        translation: translationFR
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: "en",
    useSuspense: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;