import React, { useState, useEffect }  from 'react';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AuthPage from './components/Login/AuthPage';
import ConsentForm from './components/Consent/ConsentForm';
import ConsentConfirmedInvalidLink from './components/StaticRedirects/ConsentConfirmedInvalidLink';
import UrlExpired from './components/StaticRedirects/UrlExpired';
import GenericError from './components/StaticRedirects/GenericError';
import ConsentConfirmedThankYou from './components/StaticRedirects/ConsentConfirmedThankYou';
import Cookies from 'universal-cookie';
import i18n from './i18n';
import './App.css';


function App() {
  const queryString = window.location.search;
  const requestId = new URLSearchParams(queryString).get('id') ?? '';
  const [isValid, setIsValid] = useState(false);
  const [consentRequestStatus, setConsentRequestStatus] = useState(0);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFormAccepted, setIsFormAccepted] = useState(false);
  const [isFormDeclined, setIsFormDeclined] = useState(false);
  const [phone, setPhone] = useState('');
  const [lenderId, setLenderId] = useState('');
  const [language, setLanguage] = useState('');
  const [t] = useTranslation();


  useEffect(() => {
    if(!error) {
      const GetConsentRequestStatus = () => {
        console.log("REACT_APP_API: "+ window.ENV.REACT_APP_API);
        console.log("REACT_APP_IMAGES: "+ window.ENV.REACT_APP_IMAGES); 
        fetch(process.env.REACT_APP_API + `GetConsentRequestStatus?id=${requestId}&clientApp=true`, {
        //fetch(window.ENV.REACT_APP_API + `GetConsentRequestStatus?id=${requestId}&clientApp=true`, {
          method: 'GET',
          headers: {
            accept: 'application/json',
          },
        })
        .then(function(res) {  
            if(res.status !== 500)
            {
              return res.json();
            }
            setConsentRequestStatus(10);
            return;        
        })       
        .then((result) => {
          if(result == null || result.phone == null || result.consentStatus == null) {
            setError(true);
          }
          else {
            setConsentRequestStatus(result.consentStatus); 
            setPhone(result.phone); 
            setLanguage(result.language);
            setLenderId(result.lenderId);
            i18n.changeLanguage(result.language); 
          }
          setIsLoaded(true);        
        },
        (error) => { 
          setError(error);
          setIsLoaded(true);
        })
      }
      GetConsentRequestStatus();
    }
  },[requestId, error])
  
  const authPageAppValues = (value) => {
     setIsValid(value.validated);
  }

  const acceptConsentForm = () => {
    setIsFormAccepted(true);
    setIsValid(true);
    logOut();
  }

  const declineConsentForm = () => {
    setIsFormDeclined(true);
    setIsValid(true);
    logOut();
  }

  useEffect(() => {
    // needed to hide consent form page
  },[isValid])

  useEffect(() => {
    return () => {
      // need to log out if page is refreshed  
      window.addEventListener("beforeunload", function(e) { 
        logOut();   
      }); 
    }    
  })

  const logOut = (isValid = true) => {
    let sessionStorage = window.sessionStorage;
    let needToLogOut = sessionStorage.getItem('needToLogOut');   
    if (needToLogOut == null)
    {
      sessionStorage.setItem('needToLogOut', false);
      needToLogOut = sessionStorage.getItem('needToLogOut');
    }   
    let cookies = new Cookies();
    if (needToLogOut === "true") {
      sessionStorage.setItem('needToLogOut', false);
      cookies.remove("jwt");
      fetch(process.env.REACT_APP_API + `Logout?id=${requestId}`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          secure: true,
          sameSite: 'none'
        },
        credentials: 'include',
      })
      .then(function() {
        cookies.remove("jwt");
      })      
      .then(function() {
        // if invalid, it will redirect to login page 
        setIsValid(isValid);
      }) 
    }     
  }


  if (error) {
    return <GenericError t={t}/> 
  } else if (!isLoaded) {
      return<div className="circularprocess"> <CircularProgress /></div>;
  } else {
      if(!isValid) {
        switch(consentRequestStatus) {
          case 1:
            return <AuthPage id={requestId} phone={phone} lenderId={lenderId} language={language} t={t} authPageAppValues={authPageAppValues}/>
          case 2:
            return <ConsentConfirmedInvalidLink 
                      lenderId={lenderId} 
                      language={language}
                      documentImageInvalid="./accepted-document-image-invalid-link.svg"
                      underLogoText=""//"TECHNOLOGY THAT DRIVES YOU."
                      headerText={<h4 className="confirmed__items-color-blue"><b>{t("Thankyou!")}</b></h4>}
                      bodyText={<p>{t("LinkNoLongerValid")} <b className="confirmed__items-color-blue">{t("Accepted")}</b> {t("YourConsentForm")}.</p>} /> 
          case 3:
            return <ConsentConfirmedInvalidLink 
                      lenderId={lenderId} 
                      language={language}
                      documentImageInvalid="./declined-document-image-invalid-link.svg"
                      underLogoText=""//"TECHNOLOGY THAT DRIVES YOU."
                      headerText={<h4 className="confirmed__items-color-red"><b>{t("Thankyou!")}</b></h4>}
                      bodyText={<p>{t("LinkNoLongerValid")} <b className="confirmed__items-color-red">{t("Declined")}</b> {t("YourConsentForm")}.</p>} />
          case 4:
            return <UrlExpired 
                      lenderId={lenderId}  
                      language={language}
                      t={t}/>
          case 8:
            return <ConsentConfirmedInvalidLink 
                      lenderId={lenderId} 
                      language={language}
                      documentImageInvalid="./declined-document-image-invalid-link.svg"
                      underLogoText=""//"TECHNOLOGY THAT DRIVES YOU."
                      headerText={<h4 className="confirmed__items-color-red"><b>{t("Thankyou!")}</b></h4>}
                      bodyText={<p>{t("Cancelled")}</p>} />
          default:
            return <GenericError t={t}/>
        }
      }
      else if (isValid) {
        if(consentRequestStatus === 1 && !isFormDeclined && !isFormAccepted) {
          return ( 
            <ConsentForm 
              lenderId={lenderId} 
              language={language}
              id={requestId} 
              isValid={isValid} 
              logOut={logOut} 
              acceptConsentForm={acceptConsentForm} 
              declineConsentForm={declineConsentForm}
          />)
        }
        if (isFormAccepted) {
          return <ConsentConfirmedThankYou 
                lenderId={lenderId} 
                language={language}
                headerText={t("Success")} 
                bodyText={<p>{t("YouHave")} <b className="thank-you__items-color-blue">{t("accepted")}</b> {t("YourConsentForm")}.</p>} 
                itemsClassColor="thank-you__items-color-blue" 
                checkmarkIcon="./success-check-accept.svg"
                altCheckmarkIcon="success logo"/>
        }
        if (isFormDeclined) {
          return <ConsentConfirmedThankYou 
                lenderId={lenderId} 
                language={language}
                headerText={t("Success")}
                bodyText={<p>{t("YouHave")} <b className="thank-you__items-color-red">{t("Declined")}</b> {t("YourConsentForm")}.</p>} 
                itemsClassColor="thank-you__items-color-red" 
                checkmarkIcon="./success-check-decline.svg"
                altCheckmarkIcon="success logo"/>
        }
      }
      else {
        return <GenericError t={t}/> 
      }
  }
}

export default (App);
